var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{attrs:{"id":"loginbox"}},[_c('img',{attrs:{"src":require("@/assets/img/YonghuiSync/post.png")}}),_c('van-form',{ref:"form",staticClass:"lform",on:{"submit":_vm.onSubmit}},[_c('van-field',{staticClass:"lform-input key",attrs:{"placeholder":"请输入电商平台订单号","rules":[
          {
            required: true,
            message: '请输入电商平台订单号'
          }
        ]},model:{value:(_vm.form.orderNo),callback:function ($$v) {_vm.$set(_vm.form, "orderNo", $$v)},expression:"form.orderNo"}}),_c('van-button',{staticClass:"submit",attrs:{"loading":_vm.loading,"native-type":"submit"}},[_vm._v("前往兑换")])],1),_vm._m(0),_vm._m(1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDialog),expression:"showDialog"}],staticClass:"overlay"},[_c('div',{staticClass:"center"},[_c('img',{attrs:{"src":require("@/assets/img/YonghuiSync/warm.png")}}),_c('h1',[_vm._v("温馨提示")]),_vm._m(2),_c('button',{on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("好的")])])]),_c('van-popup',{staticClass:"popReset",attrs:{"id":"loginbox","close-on-click-overlay":false,"close-icon":"close","closeable":""},model:{value:(_vm.phoneVisible),callback:function ($$v) {_vm.phoneVisible=$$v},expression:"phoneVisible"}},[_c('van-form',{ref:"form",staticClass:"lform popForm",on:{"submit":_vm.doSubmit}},[_c('div',{staticClass:"tip"},[_vm._v("京东账号名获取方式:打开京东app，进入“我的”，点击“设置”即可看到账号名")]),_c('van-field',{staticClass:"lform-input buyerAccount",attrs:{"placeholder":"请输入京东账号名","name":"buyerAccount","rules":[
          {
            required: true,
            message: '请输入京东账号名'
          }
        ]},model:{value:(_vm.form.buyerAccount),callback:function ($$v) {_vm.$set(_vm.form, "buyerAccount", $$v)},expression:"form.buyerAccount"}}),_c('van-field',{staticClass:"lform-input phone",attrs:{"placeholder":"请输入订单收货手机号","name":"phone","rules":[
          {
            required: true,
            validator: (val) => /^1[3|4|5|6|7|8|9][0-9]{9}$/.test(val),
            message: '请填写正确的手机号'
          }
        ]},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('van-field',{staticClass:"lform-input yzm",attrs:{"placeholder":"请输入验证码","rules":[
          {
            required: true,
            message: '请输入验证码'
          }
        ]},scopedSlots:_vm._u([{key:"button",fn:function(){return [(!_vm.time)?_c('div',{staticClass:"cdown",on:{"click":_vm.sendCode}},[_vm._v("发送验证码")]):_c('van-count-down',{staticClass:"cdown",attrs:{"time":_vm.time},on:{"finish":function($event){_vm.time = 0}}})]},proxy:true}]),model:{value:(_vm.form.captchaCode),callback:function ($$v) {_vm.$set(_vm.form, "captchaCode", $$v)},expression:"form.captchaCode"}}),_c('van-button',{staticClass:"submit",attrs:{"loading":_vm.loading,"native-type":"submit"}},[_vm._v(" 前往兑换 ")])],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"prompt important"},[_c('div',{staticClass:"prompt-title"},[_c('img',{attrs:{"src":require("@/assets/img/YonghuiSync/prompt_icon.png"),"alt":""}}),_c('span',{staticClass:"text"},[_vm._v("防骗公告")])]),_c('div',{staticClass:"content"},[_c('p',{staticClass:"err"},[_vm._v(" 兑换后卡券不可退换、不可挂失，请妥善保管卡券信息，切勿发给他人，谨防泄露！ ")]),_c('p',[_vm._v(" 本店没有小号旺旺，没有QQ、微信客服，从不刷信誉、做任务，从未委托任何机构或个人以“刷单返利”、“刷信誉”等方式购买商品，凡是要求提供手机验证码、远程共享屏幕都是为了骗你卡密，请广大消费者提高警惕，谨防上当受骗！ ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"prompt"},[_c('div',{staticClass:"prompt-title"},[_c('img',{attrs:{"src":require("@/assets/img/YonghuiSync/tips.png"),"alt":""}}),_c('span',{staticClass:"text"},[_vm._v("领取说明")])]),_c('div',{staticClass:"content"},[_c('p',[_vm._v(" 1、接收验证码的手机号请填写平台收货手机号，领取后不支持退款！ ")]),_c('p',[_vm._v(" 2、如遇兑换量大或网络拥堵，券码出库可能会有所延迟，建议您耐心等待若有疑问可咨询店铺在线客服。 ")]),_c('p',[_vm._v(" 3、若需要查看之前已领取的商品，则需要输入之前的订单号以及手机号进行重新验证即可查看。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" 该兑换码已兑换，若需要查看已兑换的券码，请输入 "),_c('span',[_vm._v("首次兑换时的手机号码")])])
}]

export { render, staticRenderFns }